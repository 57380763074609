//
// Kanban Board
//

// Variables
$kanban-spacer-y: 1.25rem;
$kanban-spacer-x: 1.25rem;

.kanban-container {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;

    .kanban-board {
        float: none;
        flex-shrink: 0;
        margin-bottom: $kanban-spacer-y;
        margin-right: $kanban-spacer-x !important;
        background-color: var(--kt-gray-100);
        @include border-radius($border-radius);

        &:last-child {
            margin-right: 0 !important;
        }

        .kanban-board-header {
            @include border-top-start-radius($border-radius);
            @include border-top-end-radius($border-radius);

            .kanban-title-board {
                font-size: 1.2rem;
                font-weight: 500;
                color: var(--kt-gray-900);
            }

            @each $name, $color in $theme-colors {
                &.#{$name} {
                    background-color: var(--kt-#{$name});
                    box-shadow: none;

                    .kanban-title-board {
                        color: var(--kt-#{$name}-inverse);
                    }
                }

                &.light-#{$name} {
                    color: rgba(var(--kt-#{$name}), 0.1);
                    box-shadow: none;

                    .kanban-title-board {
                        color: var(--kt-#{$name});
                    }
                }
            }
        }

        .kanban-drag {
            .kanban-item {
                @include border-radius($border-radius);
                box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
                background: var(--kt-body-bg);

                @each $name, $color in $theme-colors {
                    &[data-class="#{$name}"] {
                        background-color: var(--kt-#{$name});
                        color: var(--kt-#{$name}-inverse);
                        box-shadow: none;
                    }

                    &[data-class="light-#{$name}"] {
                        background-color: var(--kt-#{$name}-light);
                        color: var(--kt-#{$name});
                        box-shadow: none;
                    }
                }
            }
        }

        footer {
        }
    }
}

// Fixed height
.kanban-fixed-height {
    .kanban-container {
        .kanban-board {
            .kanban-drag {
                position: relative;
                overflow-y: auto;
            }
        }
    }
}
