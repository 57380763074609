//
// Gmaps Plugin
//

.gmaps {
    /* important!  bootstrap sets max-width on img to 100% which conflicts with google map canvas*/
    img {
        max-width: none;
    }

    &.gmaps-static {
        > div {
            background-repeat: no-repeat;
            background-position: 50% 50%;
            display: block;
        }
    }
}
