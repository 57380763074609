//
// SweetAlert2
//

// Base
html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open) {
    overflow-y: initial !important;

    &:not(.sweetalert2-nopadding) {
        padding-right: 0 !important;
    }
}

// Popup
.swal2-popup {
    background-color: var(--kt-body-bg);
    padding: 2rem;
    @include border-radius($border-radius);

    .swal2-title {
        font-weight: 500;
        font-size: 1.3rem;
        color: var(--kt-dark);
    }

    .swal2-html-container,
    .swal2-content {
        font-weight: normal;
        font-size: 1.1rem;
        margin-top: 1.5rem;
        color: var(--kt-gray-800);
    }

    .btn {
        margin: 15px 5px 0;
    }

    .swal2-styled {
        &:focus {
            box-shadow: none;
        }
    }

    .swal2-actions {
        margin: 1.5rem auto 1rem auto;
    }
}

// Container
.swal2-container {
    overflow-y: hidden !important;

    &.swal2-shown {
        background-color: rgba($black, 0.2);
    }

    // Content
    .swal2-html-container {
        max-height: 200px;
        overflow: auto;
    }
}

body.swal2-height-auto {
    height: 100% !important;
}

// State Colors
.swal2-icon {
    // Warning
    &.swal2-warning {
        border-color: var(--kt-warning);
        color: var(--kt-warning);
    }

    // Error
    &.swal2-error {
        border-color: var(--kt-danger);
        color: var(--kt-danger);

        [class^=swal2-x-mark-line] {
            background-color: rgba(var(--kt-danger-rgb), 0.75);
        }
    }

    // Success
    &.swal2-success {
        border-color: var(--kt-success);
        color: var(--kt-success);

        [class^=swal2-success-line] {
            background-color: var(--kt-success);
        }

        .swal2-success-ring {
            border-color: rgba(var(--kt-success-rgb), 0.3);
        }
    }

    // Info
    &.swal2-info {
        border-color: var(--kt-info);
        color: var(--kt-info);
    }

    // Question
    &.swal2-question {
        border-color: var(--kt-primary);
        color: var(--kt-primary);
    }
}
