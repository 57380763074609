//
// Tagify
//

// Bootstrap .form-control sizing integration
$tagify-tag-padding-y-sm: 0.3rem;
$tagify-tag-padding-x-sm: 0.3rem;
$tagify-input-padding-y-sm: #{$input-padding-y-sm - $tagify-tag-padding-y-sm};

$tagify-tag-padding-y: 0.4rem;
$tagify-tag-padding-x: 0.4rem;
$tagify-input-padding-y: $input-padding-y - $tagify-tag-padding-y;

$tagify-tag-padding-y-lg: 0.5rem;
$tagify-tag-padding-x-lg: 0.5rem;
$tagify-input-padding-y-lg: $input-padding-y-lg - $tagify-tag-padding-y-lg;

// Base
.tagify {
    --tagify-dd-bg-color: var(--kt-body-bg);
    --tags-border-color: var(--kt-input-border-color);
    --tags-hover-border-color: var(--kt-input-border-color);
    --tags-focus-border-color: var(--kt-input-focus-border-color);
    --tag-bg: var(--kt-gray-200);
    --tag-hover: var(--kt-gray-200);
    --tag-text-color: var(--kt-gray-700);
    --tag-text-color--edit: var(--kt-gray-700);
    --tag-pad: 0 0.5rem;
    --tag-inset-shadow-size: 1rem;
    --tag-invalid-color: var(--kt-danger);
    --tag-invalid-bg: var(--kt-danger-light);
    --tag-remove-bg: var(--kt-gray-200);
    --tag-remove-btn-color: transparent;
    --tag-remove-btn-bg: transparent;
    --tag-remove-btn-bg--hover: transparent;
    --input-color: var(--kt-gray-700);
    --placeholder-color: var(--kt-gray-400);
    --placeholder-color-focus: var(--kt-gray-500);
    --loader-size: .8rem;

    .tagify__tag {
        background-color: var(--tag-bg);
        margin: 0;
        line-height: 1;

        div {
            border-radius: inherit;
        }

        .tagify__tag-text {
            overflow: visible;
        }

        .tagify__tag__removeBtn {
            width: 0.6rem;
            height: 0.6rem;
            margin: 0 0.5rem 0 0;
            border-radius: 0;
            content: " ";
            @include svg-bg-icon(close, var(--kt-gray-500));

            &:after {
                display: none;
            }

            &:hover {
                @include svg-bg-icon(close, var(--kt-primary));
            }
        }

        // Not allowed state
        &.tagify--notAllowed {
            div {
                .tagify__tag-text {
                    color: var(--kt-danger);
                    opacity: .5;
                }
            }

            .tagify__tag__removeBtn {
                opacity: .5;
                @include svg-bg-icon(close, var(--kt-danger));

                &:hover {
                    background: transparent;
                    opacity: .75;
                }
            }
        }
    }

    .tagify__input {
        margin: 0;

        &:before {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
        }
    }

    // Sizing
    &.form-control {
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }

    &.form-control-sm {
        border-radius: $btn-border-radius-sm;
        min-height: $input-height-sm;
        padding-top: $tagify-input-padding-y-sm;
        padding-bottom: $tagify-input-padding-y-sm;

        .tagify__tag {
            padding: $tagify-tag-padding-y-sm $tagify-tag-padding-x-sm;
            border-radius: $btn-border-radius-sm;

            .tagify__tag-text {
                font-size: $input-btn-font-size-sm;
            }
        }
    }

    &:not(.form-control-sm):not(.form-control-lg) {
        border-radius: $btn-border-radius;
        min-height: $input-height;
        padding-top: $tagify-input-padding-y;
        padding-bottom: $tagify-input-padding-y;

        .tagify__tag {
            padding: $tagify-tag-padding-y $tagify-tag-padding-x;
            border-radius: $btn-border-radius;

            .tagify__tag-text {
                font-size: $input-btn-font-size;
            }
        }
    }

    &.form-control-lg {
        border-radius: $btn-border-radius-lg;
        min-height: $input-height-lg;
        padding-top: $tagify-input-padding-y-lg;
        padding-bottom: $tagify-input-padding-y-lg;

        .tagify__tag {
            padding: $tagify-tag-padding-y-lg $tagify-tag-padding-x-lg;
            border-radius: $btn-border-radius-lg;

            .tagify__tag-text {
                font-size: $input-btn-font-size-lg;
            }
        }
    }
}

// Dropdown
.tagify__dropdown {
    box-shadow: var(--kt-dropdown-box-shadow);
    border: 0 !important;
    outline: none !important;
    padding: 0.75rem 0;
    z-index: $zindex-dropdown;
    background-color: var(--kt-body-bg);
    @include border-radius($border-radius);

    // Wrapper
    ._wrapper {
        max-height: none;
        @include border-radius($border-radius);
    }

    // In modal
    .modal-open & {
        z-index: $zindex-modal + 1;
    }

    .tagify__dropdown__wrapper {
        background-color: var(--kt-body-bg);
        border: 0 !important;
        outline: none !important;
        box-shadow: none;
    }

    .tagify__dropdown__item {
        color: var(--kt-gray-700);
        border-radius: 0;
        padding: 0.75rem 1.5rem;
        margin: 0;
        box-shadow: none;
        font-weight: $font-weight-semibold;

        &:hover,
        &.tagify__dropdown__item--active {
            background-color: var(--kt-component-hover-bg);
            color: var(--kt-component-hover-color);
        }
    }

    // Suggestions
    &.tagify__inline__suggestions {
        padding: $input-padding-y $input-padding-x;

        .tagify__dropdown__item {
            display: inline-block;
            font-size: $font-size-sm;
            padding: 0.35rem 0.5rem;
            margin: 0.25rem 0.5rem 0.25rem 0;
            background-color: var(--kt-gray-200);
            color: var(--kt-gray-700);
            @include border-radius($border-radius);

            &:hover,
            &.tagify__dropdown__item--active {
                background-color: var(--kt-component-hover-bg);
                color: var(--kt-component-hover-color);
            }
        }
    }
}
